export const firstChartConfig = {
  type: "pie",
  data: {
    labels: [
      "Pre-subs",
      "Governance",
      "Minting Fund",
      "Team",
      "Drops/Promotions",
    ],
    datasets: [
      {
        label: "RED EYE",
        data: [50, 10, 15, 20, 5],
        backgroundColor: [
          "#DD3239",
          "#32DDDD",
          "#32DD76",
          "#FE6A16",
          "#FEE716",
        ],
        borderColor: ["#DD3239", "#32DDDD", "#32DD76", "#FE6A16", "#FEE716"],
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        labels: {
          color: "#f1f1f1",
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  },
};

export const secondChartConfig = {
  type: "pie",
  data: {
    labels: [
      "Development",
      "Marketing Partnerships",
      "Platform Expansion",
      "Reserves",
    ],
    datasets: [
      {
        label: "RED EYE",
        data: [30, 30, 15, 15],
        backgroundColor: ["#FECB16", "#EBFE16", "#1665FE", "#16FEFE"],
        borderColor: ["#FECB16", "#EBFE16", "#1665FE", "#16FEFE"],
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        labels: {
          color: "#f1f1f1",
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  },
};
