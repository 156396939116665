export const translations = {
  en: {
    home_heading_1: "TO",
    home_heading_2: "CONTROL",
    home_heading_3: "THE MEDIA YOU",
    home_heading_4: "MUST",
    home_heading_5: "BECOME",
    home_heading_6: "THE",
    home_heading_7: "MEDIA",

    home_heading_desc:
      "Lets turn every lens on every phone into a window to the world, every mobile device user into a potential correspondent.",
    home_heading_buy_red: "BUY RED NOW",
    home_heading_unedited: "Unedited",
    home_heading_uncut: "Uncut",
    home_heading_unbiased: "Unbiased",
    home_heading_b_1: "Every broadcast is an NFT",
    home_heading_b_2: "uniquely owned by its",
    home_heading_b_3: "creator or token holder",
    home_heading_community: "Community",
    home_heading_governed: "Governed",

    home_become_media: "BECOME THE MEDIA",
    home_become_media_heading_p1: "The World’s First Decentralized",
    home_become_media_heading_p2: "Breaking News Marketplace.",
    home_become_media_p1:
      "RedEyed aims to create the world's first truly decentralized, community governed Global Breaking News Marketplace.",
    home_become_media_p2:
      "We aim to reduce the bias and imbalance in the way breaking and developing stories are presented to the world.",
    home_become_media_p3:
      "Our community will bridge the gap between the time it takes a journalist to get on the scene to develop a story and the crucial first images raw and uncut.",

    home_problems_heading_1: "UNCUT, UNEDITED, LIVE...",
    home_problems_heading_2: "PROBLEMS AND ",
    home_problems_heading_3: "CHALLENGES",
    home_problems_heading_4: "TODAY'S MEDIA FACING",
    home_problems_heading_desc:
      "For decades we have known or suspected that the media is mainly controlled by a few interest groups, that determine what makes the headlines and what doesn't.",
    home_problems_heading: "PROBLEMS",
    home_problems_p1:
      "At any given moment an untold number of good, bad, horrible, funny and or uplifting stories are developing all over the world.",
    home_problems_p2_1: "In our view there are 3 types of stories, ",
    home_problems_p2_2: "BREAKING, DEVELOPING and OLD.",
    home_problems_p3:
      "When, How and If those stories get to you will be determined by a lot of factors some of which are listed below:",
    home_problems_b_1:
      "The groups of interests it may impact along the way, this impact can be financial as well as political.",
    home_problems_b_2:
      "The region or country where you live, as local authorities my seek to keep the incident localized.",
    home_problems_b_3:
      "Local Networks capacity to get someone onto the scene on time, even those with news choppers fail to get there at the moment it breaks.",
    home_problems_b_4:
      "Editors willingness to allow a story to move forward without succumbing to pressure from above.",
    home_problems_b_5:
      "The networks ability to determine the veracity or authenticity of the story and its actors as well as the setting of it.",
    home_problems_b_6:
      "With the current system, by design and how it is structured you will always have an older version of the story that is handpicked and curated for you.",
    home_challenges_heading: "CHALLENGES",
    home_challenges_heading_1: "Too Slow to Get to there on Time",
    home_challenges_b_1_1:
      "Even networks with massive resources in some of the largest metro areas simply cannot get there on time. By the time they manage to get a manicured face onto the scene with a broadcast team it is no longer a breaking story, it is rather a developing one and they are actively trying to collect details on what transpired before.",
    home_challenges_b_1_2:
      "If the incident is across borders the network is left scouring social media and other outlets to be able to bring it to their audiences homes.",
    home_challenges_heading_2: "Sourced and Curated for a Desired Effect",
    home_challenges_b_2:
      "Most news and stories you see, hear or read about are sourced from a massive central source that puts forward a narrative that then sets the tone for any incident. By the time you see it, your reactions have already been expected and planned for. You may not even be getting news but actually a scripted version of events if you get the events at all and in the correct sequence.",
    home_challenges_heading_3: "Fake News...",
    home_challenges_b_3_1:
      "In an attempt to keep up with social media, many networks have moved to retweet or re-broadcast content they pick up online. Sometimes they run with a story or narrative that is entirely false and leads to embarrassing retractions.",
    home_challenges_b_3_2:
      "As technology grows, with deepfake among other advances, this will only increase and make it even harder for journalists to keep up with the onslaught of unverified content made available to the public at large.",

    home_solution_heading_1: "OUR",
    home_solution_heading_2: "SOLUTION",
    home_solution_desc:
      "There are 5.27 billion unique mobile phone users in the world today, according to the latest data from GSMA Intelligence.",
    home_solution_sub_heading_1: "Every Phone is a Mic and Camera",
    home_solution_sub_p1_1:
      "With RedEyed every mobile device is a potential microphone and camera, turning every user into a potential correspondent for the breaking story.",
    home_solution_sub_p1_2:
      "This completely decentralizes the source of the story and eliminates any narrative beyond what is captured by those in the immediate vicinity.",
    home_solution_sub_heading_2: "Sourced and Curated by the Community",
    home_solution_sub_p2_1:
      "With RedEyed the source of every story is a community user, it is confirmed by other community users with live video from the same scene and it is unedited, unscripted and live.",
    home_solution_sub_p2_2:
      "The way our app works, nothing can be uploaded into our network unless it is recorded live. Every piece of footage is unique and every user own’s each piece of footage until ownership is transferred via auction in our NFT marketplace.",
    home_solution_sub_heading_3: "Instant Access to the News Room",
    home_solution_sub_p3_1:
      "Our NFT marketplace allows News organizations to BID on Breaking stories by the minute as they go live.",
    home_solution_sub_p3_2:
      "This gives the News room an opportunity to keep their audiences informed until they can have a crew on the scene to transition into a developing story.",
    home_solution_sub_p3_3:
      "Once ownership is transferred, the new owner gets direct access and can use it at their discretion. RedEyed always retains broadcast access while the story is live and as proof of origin. If it was on RedEyed then it was here first. If you hold the token its yours forever.",
    home_solution_buy_red: "BUY RED NOW",
    home_solution_sub_heading_4: "THE SOLUTION WAS ALREADY HERE.",
    home_solution_sub_p4_1:
      "On a daily basis the large majority of all mobile devices are active on the minute of every hour.",
    home_solution_sub_p4_2:
      "Each of these devices has a camera with few exceptions.",
    home_solution_sub_p4_3:
      "what happens if we turn each lens into a potential correspondent for media outlets and each user into a digital reporter?",
    home_solution_sub_b_1:
      "Mobile users are always on the scene at the exact moment a story breaks.",
    home_solution_sub_b_2:
      "With our App each user can only shoot live, unedited, uncut footage.",
    home_solution_sub_b_3:
      "The footage is streamed live into our platform where it is available to our entire Apps audience.",
    home_solution_sub_b_4:
      "The same footage is also immediately made available to all Media Outlets who may wish to expand it to their local audience as it happens live.",
    home_solution_sub_b_5:
      "Every broadcast is a unique NFT assigned to the user on the scene, so the source is unequivocal.",
    home_solution_sub_b_6:
      "News Organizations will be able to Bid instantly for every piece of live footage as it enters our marketplace and buy the rights to re-transmit to their respective audiences. All profits excepting Minting and platform fees go the creator.",
    home_solution_sub_b_7:
      "A story is validated as more POVs are streamed for the same incident or validated by the community or as it is developed by local media.",
    home_solution_sub_b_8:
      "Every piece of footage is documented from its creation for the rest of its existence, a chain of custody supported by its unique address.",

    home_token_heading_1: "THE",
    home_token_heading_2: "RED TOKEN",
    home_token_sub_heading_1: "Our Token",
    home_token_sub_p1_1:
      "RED is a BEP20 token and is the life blood of our community and ecosystem, as the token is utilized by users to",
    home_token_sub_p1_2: "mint",
    home_token_sub_p1_3: "their broadcasts into NFTs,",
    home_token_sub_p1_4: "acquire footage",
    home_token_sub_p1_5: "in our marketplace and",
    home_token_sub_p1_6: "promote and curate",
    home_token_sub_p1_7:
      "their videos. It is also the only accepted currency to",
    home_token_sub_p1_8: "run Ads",
    home_token_sub_p1_9:
      "on our platform, and how our creators are paid a share of all profits their videos generate from",
    home_token_sub_p1_10: "Ad sales.",
    home_token_sub_p1_11:
      "Finally RED is also how our governance is rewarded for holding/staking RED and policing the platform.",
    home_token_sub_heading_2: "The Pre-subscriptions - Closed",
    home_token_sub_p2_1:
      "Presubscriptions allow early users, content creators and news organizations to register their account and",
    home_token_sub_p2_2: "Broadcast Names",
    home_token_sub_p2_3:
      "early into RedEye also gaining early access to a limited amount of RED tokens way before these are made available to the public on exchanges.",
    home_token_sub_p2_4:
      "There is a set cap of RED tokens made available with each subscription for obvious reasons, as to prevent any one outlet from using money to control the narrative.",
    home_token_sub_p2_5:
      "Presubscriptions are now Closed! Excess Tokens Subject to Burn!",

    home_token_sub_heading_3: "Pre-sub Details",
    home_presub_start_date: "START DATE",
    home_presub_end_date: "END DATE",
    home_presub_available_tokens: "AVAILABLE TOKENS",
    home_presub_accepted_currency: "ACCEPTED CURRENCY",
    home_presub_presub_rates: "PRESUB RATES",
    home_presub_minimum_amts: "MINIMUM AMTS.",
    home_presub_maximum_amts: "MAXIMUM AMOUNT",
    home_presub_audited_by: "AUDITED BY",
    home_presub_zeppelin: "EXTENDS OPEN ZEPPELIN",
    home_presub_completed: "COMPLETED",
    home_presub_date: "March 15th 2022",
    home_presub_period_closed: "PERIOD CLOSED",
    home_presub_join: "JOIN THE WHITELIST",

    home_presub_institutional: "Institutional",
    home_presub_creator: "Creator",
    home_presub_basic: "Basic",
    home_presub_public: "Public",
    home_presub_listing: "Listing",
    home_presub_basic_date: "April 15th 2022",
    home_presub_list_date: "July 15th 2022",
    home_presub_goal: "Goal",
    home_presub: "Pre-Sub",

    home_distribution_1: "TOKEN",
    home_distribution_2: "DISTRIBUTION",
    home_distribution_3: "AND",
    home_distribution_4: "ALLOCATION",
    home_initial_dist: "Initial Distribution",
    home_fund_alloc: "Funds Allocation",

    home_card_heading_1: "Show On The Road",
    home_card_heading_2:
      "Here’s our road map on how we're going to empower you to become",
    home_card_heading_3: "the media.",
    home_card_1:
      "The concept for Impulse RedEyed and the decentralization of breaking news was born.",
    home_card_2:
      "A centralized version of RedEye site was in the works in partnership with a Development Firm.",
    home_card_3:
      "R&D work to test current blockchain scalability to handle large volume NFTs creation.",
    home_card_4:
      "Expanded the team to work on cloud infrastructure to sustain the potential massive influx of content.",
    home_card_5:
      "Design and development of RedEyed app with an NFT decentralized marketplace at its core.",
    home_card_6: "Institutional, creator and basic presubscriptions.",
    home_card_7:
      "Beta Launch of RedEyed App and massive Stress testing Development of Web Dash.",
    home_card_8:
      "Controlled rollout of RedEyed App and its NFT Marketplace. Roll out RedEyed Advertising Web Portal.",
    home_card_9:
      "Expansion of RedEyed services R&D into Blockchain scalability improvements",

    home_bullet_heading_p1: "Every Phone is a Camera -",
    home_bullet_heading_p2: "Every Video an NFT",

    home_bullet_1: "Own your videos all across the web for eternity",
    home_bullet_2:
      "Sell your footage to the highest bidder, you shoot RedEyed handles the rest.",
    home_bullet_3:
      "Push an issue from the local stage to the global stage in a matter of minutes.",
    home_bullet_4:
      "Generate revenue from every ad sold during your videos run time.",
    home_bullet_5:
      "Become a part of the world's major networks newsroom feeds.",
    home_bullet_6: "Advertise your business directly to a global audience.",
    home_bullet_7: "Become the owner of your own press conferences.",

    home_copyright_text: "Copyright © 2022 RedEyed. All rights reserved.",

    nav_distributions: "Distributions",
    nav_privacy: "Privacy Policy",
    nav_terms_of_service: "Terms of service",
    nav_presentation: "Presentation",
    nav_problems: "Problem",
    nav_solution: "Solution",
    nav_white_paper: "Whitepaper",
    nav_pre_subs: "Pre-Subs",
    nav_contact: "Contact",
  },

  esp: {
    home_heading_1: "PARA",
    home_heading_2: "CONTROLAR",
    home_heading_3: "LOS MEDIOS",
    home_heading_4: "DEBES",
    home_heading_5: "CONVERTIRTE",
    home_heading_6: "EN LOS",
    home_heading_7: "MEDIOS",

    home_heading_desc:
      "Convirtamos cada lente de cada teléfono en una ventana al mundo, cada usuario de dispositivo móvil en un corresponsal potencial.",
    home_heading_buy_red: "COMPRAR RED",
    home_heading_unedited: "Sin editar",
    home_heading_uncut: "Sin cortes",
    home_heading_unbiased: "Imparcial",
    home_heading_b_1: "Cada emisión es un NFT",
    home_heading_b_2: "propiedad exclusiva de su",
    home_heading_b_3: "creador o titular de token.",
    home_heading_community: "Community",
    home_heading_governed: "Governed",

    home_become_media: "CONVIERTETE EN LOS MEDIOS",
    home_become_media_heading_p1: "La primera plataforma de Noticias y",
    home_become_media_heading_p2: "Primicias Decentralizado en el mundo",
    home_become_media_p1:
      "RedEyed tiene como objetivo crear el primer Global Breaking News Marketplace del mundo verdaderamente descentralizado y gobernado por la comunidad.",
    home_become_media_p2:
      "Nuestra meta es disminuir la parcialidad y falta de balance en la manera que las noticias son pesentadas al mundo",
    home_become_media_p3:
      "Nuestros usuarios reduciran la brecha entre el tiempo que le toma a un reportero llegar a la escena y las primeras igamenes cruciales de cualquier noticia",

    home_problems_heading_1: "SIN CORTE, SIN EDITAR, EN VIVO ...",
    home_problems_heading_2: "PROBLEMAS Y ",
    home_problems_heading_3: "RETOS",
    home_problems_heading_4: "A LOS QUE SE ENFRENTAN LOS MEDIOS DE HOY",
    home_problems_heading_desc:
      "Por decadas hemos sospechado que algunos medios son controlados o manipulados por grupos con intereses particulares, cuyos determinan que noticias llegan al publico.",
    home_problems_heading: "PROBLEMATICAS",
    home_problems_p1:
      "En cualquier momento hay un sin numero de historias desarrollandose en todo el mundo",
    home_problems_p2_1: "Desde nuestra perspectiva hay 3 tipos de noticias, ",
    home_problems_p2_2: "DE ULTIMA HORA, EN DESARROLLO, NOTICIA VIEJA",
    home_problems_p3:
      "Varios factores determinan cuales si algunas de estas noticias llegan al publico:",
    home_problems_b_1:
      "Los interes que pueda impactar la noticia, el impacto puede ser politico o financiero",
    home_problems_b_2: "La region or parte del mundo donde vives",
    home_problems_b_3:
      "La capaciad del noticiero de hacer llegar un reportero a la escena de los eventos a tiempo",
    home_problems_b_4:
      "La voluntad de editores para permitiar que una noticia corra sin presión desde arriba",
    home_problems_b_5:
      "La capacidad del noticiero para determinar la auntenticidad de la noticia y sus actores y confirmar el lugar de los hechos",
    home_problems_b_6:
      "El sistema actual esta diseñado para que solo veas noticias curadas y siempre veras la version mas antigua de los eventos",
    home_challenges_heading: "RETOS",
    home_challenges_heading_1: "Son muy lentos para llegar a la escena",
    home_challenges_b_1_1:
      "Has los medios con recursos masivos en las ciudades mas avanzadas se les hace dificil tener un equipo transmitiendo en la escena, cuando estos quieren llegar ya la noticia esta en desarrollo.",
    home_challenges_b_1_2:
      "Si los eventos ocurrieron en otras fronteras, al noticiero no le queda opción as que buscar fuentes a veces en las redes",
    home_challenges_heading_2:
      "Noticias Obtenidas y curadas para efectos previstos",
    home_challenges_b_2:
      "La mayoria de las noticias parecen ser obtenidas de una sola fuente que les agrega una narrativa y tono a cada evento. Cuando la llegas a ver o escuchar tus reacciones ya han sido previstas.",
    home_challenges_heading_3: "Noticias Falsas...",
    home_challenges_b_3_1:
      "En un intento de mantener su publico informado al mismo tiempo que las redes sociales, muchos medios han tomado la decision de re-postear o repetir contenido de las mismas redes. A veces corren una noticia que es totalmente falsa y esto los lleva hacer retracciones vergonzosas",
    home_challenges_b_3_2:
      "Con el avance de la tecnologia y los Deep Fakes, las noticias falsas aumetaran y haran el trabajo de los medios aun mas dificil",

    home_solution_heading_1: "NUESTA",
    home_solution_heading_2: "SOLUCIÓN",
    home_solution_desc:
      "Existen hoy en dia unos 5.27 billones de dispositivos mobiles y usarios en el mundo, de acuerdo a los datos mas recientes de GSMA Intelligence.",
    home_solution_sub_heading_1: "Cada dispositivo tiene un Microfono y Camara",
    home_solution_sub_p1_1:
      "Con Redeye cada Celular es una camara y microfono, esto convierte a cada usuario en un potencial reportero para noticias de ultima hora",
    home_solution_sub_p1_2:
      "Esto decentraliza la fuente de las noticias por completo y elimina cualquier narrativa mas alla de las imagenes capturadas y transmitida desde la escena",
    home_solution_sub_heading_2:
      "Noticias obtenidas y transmitida por los usuarios",
    home_solution_sub_p2_1:
      "Con RedEye cada noticia o primicia la fuente es un usuario, y es confirmada por otros usuarios a traves de otras imagenes obtenidas en el mismo lugar al mismo tiempo",
    home_solution_sub_p2_2:
      "La manera en que funciona nuestra App, nada puede ser cargado a nuestros canales si no es capturado EN VIVO. Cada imagen es unica y su dueño unico es quien la graba al menos que este decida venderla en nuestro marketplace.",
    home_solution_sub_heading_3: "Acceso Instantaneo para la Sala de Prensa",
    home_solution_sub_p3_1:
      "Nuestro Marketplace de NFTs permite que cualquier Medio o noticiero pueda hacer una oferta de compra por cualquier primicia al momento que estas llegan al canal en vivo",
    home_solution_sub_p3_2:
      "Esto les permite a la prensa y noticieros expandir la noticia y mantener informado desde la escena a toda su audiencia mientras ellos hacen llegar un equipo profesional a la escena.",
    home_solution_sub_p3_3:
      "Una vez adquirida un noticia o historia, el nuevo dueño puede acceder, descargar y re-transmitir a su discreción. La historia sigue en RedEye como prueba de origen",
    home_solution_buy_red: "COMPRA RED AHORA",
    home_solution_sub_heading_4: "LA SOLUCIÓN SIEMPRE ESTUVO AQUI",
    home_solution_sub_p4_1:
      "La mayoria de los dispositivos mobiles estan activos 24 horas al dia 7 dias a la semana.",
    home_solution_sub_p4_2: "Cada uno tiene una camara.",
    home_solution_sub_p4_3:
      "¿Que sucede si convertimos cada uno en una extension de cada noticiero y creador de contenido?",
    home_solution_sub_b_1:
      "Los usuarios de estos dispositivos siempre estan en la escena cuando la noticia inicia.",
    home_solution_sub_b_2:
      "Nuestra App solo permite la captura de videos y eventos en vivo, sin censura, sin filtros, sin corte.",
    home_solution_sub_b_3:
      "Las imagenes se transmiten en vivo a nuestra palataforma.",
    home_solution_sub_b_4:
      "Las mismas imagenes estan disponibles para los medios que deseen expandir la noticia a sus audiencias respectivas.",
    home_solution_sub_b_5:
      "Cada transmision es un NFT asignado al usuario que la captura, la fuente es totalmente única.",
    home_solution_sub_b_6:
      "Los Medios podran hacer ofertas directas para obtener los derechos de re-transmitir las noticias. Todas las ganancias son depositadas en la billetera del usuario a excepción del costo de plataforma.",
    home_solution_sub_b_7:
      "Las noticias son validadas por las diferentes tomas en vivo por otros usuarios en el mismo lugar.",
    home_solution_sub_b_8:
      "Cada transmision es documentada desde su creación y su cadena de custodia es grabada en el Blockchain.",

    home_token_heading_1: "EL",
    home_token_heading_2: "TOKEN RED",
    home_token_sub_heading_1: "Nuestro Token",
    home_token_sub_p1_1:
      "RED es un token BEP20 y es el elemento vital de nuestra comunidad y ecosistema, ya que los usuarios utilizan",
    home_token_sub_p1_2: "el token para ",
    home_token_sub_p1_3: "convertir sus transmisiones en NFT,",
    home_token_sub_p1_4: "adquirir imágenes",
    home_token_sub_p1_5: "en nuestro mercado y",
    home_token_sub_p1_6: "promocionar y seleccionar",
    home_token_sub_p1_7: "sus videos. También es la única moneda aceptada para",
    home_token_sub_p1_8: "publicar anuncios",
    home_token_sub_p1_9:
      "en nuestra plataforma, y ​​es la forma en que a nuestros creadores se les paga una parte de todas las ganancias que generan sus videos a partir de las ventas ",
    home_token_sub_p1_10: "de anuncios.",
    home_token_sub_p1_11:
      "Finalmente, RED también es la forma en que nuestro gobierno es recompensado por mantener/apostar por RED y vigilar la plataforma.",
    home_token_sub_heading_2: "Las Pre-inscripciones - Cerradas",
    home_token_sub_p2_1:
      "Las suscripciones previas permiten a los primeros usuarios, creadores de contenido y organizaciones de noticias registrar su cuenta y",
    home_token_sub_p2_2: "Nombres de Usuarios",
    home_token_sub_p2_3:
      "de forma anticipada en RedEye y obtener acceso prioritario a una cantidad limitada de tokens RED mucho antes de que estén disponibles para el público en los intercambios.",
    home_token_sub_p2_4:
      "Hay un límite fijo de tokens RED disponibles con cada suscripción por razones obvias, para evitar que cualquier medio use dinero para controlar la narrativa.",
    home_token_sub_p2_5:
      "¡Las presuscripciones ya están cerradas! ¡Tokens en exceso sujetos a quema!",

    home_token_sub_heading_3: "Pre-sub Details",
    home_presub_start_date: "FECHA INICIO",
    home_presub_end_date: "FECHA TERMINA",
    home_presub_available_tokens: "TOKENS DISPONIBLES",
    home_presub_accepted_currency: "MONEDA ACEPTADA",
    home_presub_presub_rates: "PRECIOS",
    home_presub_minimum_amts: "MONTO MNM",
    home_presub_maximum_amts: "MONTO MXM",
    home_presub_audited_by: "AUDITORIA POR",
    home_presub_zeppelin: "EXTIENDE OPEN ZEPPELIN",
    home_presub_completed: "COMPLETADA",
    home_presub_date: "Marzo 15 2022",
    home_presub_join: "LISTA DE ESPERA",
    home_presub_period_closed: "CERRADA",

    home_presub_institutional: "Institucional",
    home_presub_creator: "Creadores",
    home_presub_basic: "Basico",
    home_presub_public: "Listado",
    home_presub_listing: "Publico",
    home_presub_basic_date: "Abril 15 2022",
    home_presub_list_date: "Julio 15 2022",
    home_presub_goal: "Meta",
    home_presub: "Compra",

    home_distribution_1: "DISTRIBUCIÓN Y",
    home_distribution_2: "ASIGNACIÓN",
    home_distribution_3: "DE",
    home_distribution_4: "TOKENS",
    home_initial_dist: "DISTRIBUCIÓN INICIAL",
    home_fund_alloc: "Asignación de Fondos",

    home_card_heading_1: "Nuestro Mapa a futuro",
    home_card_heading_2:
      "Aquí está nuestra hoja de ruta sobre cómo vamos a empoderarlos para que se conviertan",
    home_card_heading_3: "en los medios.",
    home_card_1:
      "Nació el concepto de Impulse RedEyed y la descentralización de las últimas noticias.",
    home_card_2:
      "Se estaba trabajando en una versión centralizada del sitio RedEye en asociación con nuestra empresa de desarrollo.",
    home_card_3:
      "Trabajo de I + D para probar la escalabilidad actual de blockchain para manejar la creación de NFT de gran volumen.",
    home_card_4:
      "Se amplió el equipo para trabajar en la infraestructura de la nube para sostener la posible afluencia masiva de contenido.",
    home_card_5:
      "Diseño y desarrollo de la aplicación RedEyed con un mercado descentralizado NFT en su núcleo.",
    home_card_6: "Presuscripciones institucionales, de creador y básicas.",
    home_card_7:
      "Lanzamiento beta de la aplicación RedEyed y desarrollo masivo de pruebas de estrés de Web Dash.",
    home_card_8:
      "Despliegue controlado de la aplicación RedEyed y su NFT Marketplace. Implementar el portal web de publicidad RedEyed.",
    home_card_9:
      "Expansión de la I+D de servicios de RedEyed en mejoras de escalabilidad de Blockchain",

    home_bullet_heading_p1: "Cada teléfono es una cámara,",
    home_bullet_heading_p2: "cada video es una NFT",

    home_bullet_1: "Sea dueño de sus videos en toda la web para la eternidad",
    home_bullet_2:
      "Vende tu video al mejor postor, tú grabas y RedEyed se encarga del resto.",
    home_bullet_3:
      "Impulsa una causa del escenario local al escenario global en cuestión de minutos.",
    home_bullet_4:
      "Genere ingresos de cada anuncio vendido durante el tiempo de ejecución de sus videos.",
    home_bullet_5:
      "Conviértase en parte de las salas de redacción de las principales redes del mundo.",
    home_bullet_6: "Anuncie su negocio directamente a una audiencia global.",
    home_bullet_7:
      "Conviértete en el dueño de tus propias conferencias de prensa.",

    home_copyright_text:
      "Copyright © 2022 RedEyed. Reservados todos los derechos.",

    nav_distributions: "Distribuciones",
    nav_privacy: "Privacy Policy",
    nav_terms_of_service: "Términos de servicio",
    nav_presentation: "Presentación",
    nav_problems: "Problamaticas",
    nav_solution: "Solución",
    nav_white_paper: "Papel blanco",
    nav_pre_subs: "Presuscripciones",
    nav_contact: "Contacto",
  },
};
