import { useState, useRef, useEffect } from 'react';
import { useLanguage } from '../context/LanguageContext'

const LanguageSwitcher = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <div>
       <div>
      <div
                  style={{display: 'flex', gap: '12px'}}
                >
                  <div onClick={() => setLanguage('en')} style={{flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}} className={`d-flex links clickable ${language === 'en' ? 'activeLang' : 'inactiveLang'}`}>
                      <img
                  style={{marginRight: '5px'}}
                  width="25"
                  src="/flags/en.svg"
                  alt="english"
                />
                        <span style={{marginTop: 0}}>EN</span>
                  </div>
                  <div onClick={() => setLanguage('esp')} style={{flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}} className={`d-flex links clickable ${language === 'esp' ? 'activeLang' : 'inactiveLang'}`}>
                      <img
                  style={{marginRight: '5px'}}
                  width="25"
                  src="/flags/esp.svg"
                  alt="spanish"
                />
                        <span style={{marginTop: 0}}>ESP</span>
                  </div>
                  {/* <div onClick={() => setLanguage('ger')} style={{flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}} className={`d-flex links clickable ${language === 'ger' ? 'activeLang' : 'inactiveLang'}`}>
                      <img
                  style={{marginRight: '5px'}}
                  width="25"
                  src="/flags/ger.svg"
                  alt="german"
                />
                        <span style={{marginTop: 0}}>GER</span>
                  </div>
                  <div onClick={() => setLanguage('chn')} style={{flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}} className={`d-flex links clickable ${language === 'chn' ? 'activeLang' : 'inactiveLang'}`}>
                      <img
                  style={{marginRight: '5px'}}
                  width="25"
                  src="/flags/chn.svg"
                  alt="chinese"
                />
                        <span style={{marginTop: 0}}>CHN</span>
                  </div>
                   */}
                 
                 
                </div>
                
    </div></div>
  )
}

export default LanguageSwitcher